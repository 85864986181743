<template>
    <span>
        <v-row>
            <v-col cols="12" lg="5">
                <!-- avatar -->
                <v-card height="100%">
                    <v-card-title>Avatar</v-card-title>
                    <v-card-text class="d-flex flex-column align-center">
                        <v-color-picker dot-size="25" hide-canvas hide-inputs hide-mode-switch elevation="0" width="100%" mode="hsla" v-model="tmp_avatarColor"/>
                        <v-avatar rounded="0" size="300px" class="ma-3" :color="`hsl(${tmp_avatarColor.h}deg, ${tmp_avatarColor.s*100}%, ${tmp_avatarColor.l*100}%)`">
                            <img :src="require('@/assets/images/avatars/' + tmp_avatar)" />
                        </v-avatar>
                        <v-dialog v-model="dialog" width="1000">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :outlined="true" color="primary" v-bind="attrs" v-on="on">Changer d'avatar</v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="text-h5 lighten-2">Changer d'avatar</v-card-title>
                                <v-card-text class="d-flex flex-wrap align-center justify-center">
                                    <v-hover v-for="(image, i) in profileImages" :key="i">
                                        <template v-slot:default="{ hover }">
                                            <v-card :elevation="hover ? 6 : 24" class="ma-3">
                                                <v-avatar rounded="0" size="100" :color="`hsl(${tmp_avatarColor.h}deg, ${tmp_avatarColor.s*100}%, ${tmp_avatarColor.l*100}%)`" class="customWhite--text" style="cursor: pointer" @click="changeAvatar(image)">
                                                    <img :src="require('@/assets/images/avatars/' + image)"/>
                                                </v-avatar>
                                            </v-card>
                                        </template>
                                    </v-hover>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-card-text>
                </v-card>
            </v-col>
            <!-- Informations -->
            <v-col cols="12" lg="7">
                <v-card height="100%">
                    <v-card-title>Informations</v-card-title>
                    <v-card-text>
                        <v-form class="multi-col-validation d-flex flex-column gap-l">
                            <v-text-field label="Prenom" dense outlined v-model="profile.firstname" />
                            <v-text-field label="Nom" dense outlined v-model="profile.lastname" />
                            <v-text-field label="E-mail" dense outlined disabled v-model="profile.email" />
                            <v-text-field label="Role" dense outlined disabled v-model="profile.role" v-if="!['User', 'None'].includes(profile.role)" />

                            <div v-if="!['User', 'None'].includes(profile.role) && licencesTable && Object.keys(licencesTable).length != 0">
                                <h4>Licence</h4>
                                <p class="mb-0 ml-5" v-if="profile.role == 'SuperAdmin'">En tant que SuperAdmin vous avez acces a toutes les licences</p>
                                <p class="mb-0 ml-5" v-else>Vous etes sous licence <b v-text="licencesTable[profile.licence].name"></b></p>
                                <p class="mb-0 ml-5" v-if="profile.role == 'Admin'" v-text=" '( users : ' + licencesTable[profile.licence].userAmount + '/' + licencesTable[profile.licence].maxUserAmount +' rooms : ' + licencesTable[profile.licence].userAmount + '/' + licencesTable[profile.licence].maxUserAmount + ' )' "></p>
                            </div>

                            <div v-if="roomsTable && Object.keys(roomsTable).length != 0 && profile.role != 'None'">
                                <h4>Rooms</h4>
                                <p class="mb-0 ml-5" v-if="profile.role == 'SuperAdmin'">En tant que SuperAdmin vous avez acces a toutes les rooms</p>
                                <div v-else class="d-flex flex-wrap gap-m pa-2">
                                    <div v-for="room of availableRooms">
                                        <v-btn v-if="profile.currentRoom === room.id" small style="font-size:1em" color="darkBg" class="normal-case cursor-default">
                                            <v-icon class="mr-1">mdi-check-circle-outline</v-icon>
                                            {{ room.name }}
                                        </v-btn>
                                        <v-btn v-else small style="font-size:1em" outlined class="normal-case" @click="profile.currentRoom = room.id; saveChanges(true)">
                                            <v-icon class="mr-1">mdi-checkbox-blank-circle-outline</v-icon>
                                            {{ room.name }}
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>

            <!-- Préférences -->
            <v-col>
                <v-card>
                    <v-card-title>Préférences</v-card-title>
                    <v-card-text class="d-flex flex-column align-start gap-l">
                        <!-- theme -->
                        <!-- TODO: themes -->
                        <!-- <div class="d-flex align-center mb-1">
                            <b>Theme : </b>
                            <v-switch class="pl-3 pt-0 mt-0" color="light" v-model="newInstance_isDark" @change="updateTheme" hide-details :label="newInstance_isDark ? 'Sombre' : 'Claire'" />
                        </div> -->
                        <!-- font size -->
                        <div class="align-center" style="display:grid;grid-template-columns:auto 1fr;width:100%;">
                            <b style="font-size:14px" class="mr-3">Taille du text :</b>
                            <div :style="$vuetify.breakpoint.smAndDown ? 'grid-column: span 2;' : ''">
                                <v-slider @input="updateFontSize" min="75" max="150" v-model="fontSize" append-icon="mdi-magnify-plus-outline" prepend-icon="mdi-magnify-minus-outline" @click:append="fontSize++; updateFontSize(fontSize)" @click:prepend="fontSize--; updateFontSize(fontSize)" hide-details>
                                    <template v-slot:thumb-label="{ value }"><span class="white--text">{{ value + (value < 100 ? '%' : '') }}</span></template>
                                </v-slider>
                            </div>
                        </div>
                        <!-- notifications -->
                        <div class="d-flex flex-column" v-if="profile.role != 'None'">
                            <!-- sound -->
                            <div class="d-flex flex-wrap align-center">
                                <b class="mr-3">Notifications : </b>
                                <div class="d-flex flex-wrap align-center justify-center gap-xs pa-2">
                                    <div v-for="number in 7">
                                        <v-btn v-if="(number === notifNumber)" color="primary" x-small style="font-size:1em" class="BigOutline" outlined @click="testNotif(number)">{{ number }}</v-btn>
                                        <v-btn v-else color="darkBg" x-small style="font-size:1em" @click="testNotif(number)">{{ number }}</v-btn>
                                    </div>
                                </div>
                            </div>
                            <!-- volume -->
                            <div class="align-center" style="display:grid;grid-template-columns:auto 1fr">
                                <v-btn icon small @click="muteNotif">
                                    <v-icon v-if="notifVolume === 0 || notifAreMuted">mdi-volume-off</v-icon>
                                    <v-icon v-else-if="notifVolume <= 33">mdi-volume-low</v-icon>
                                    <v-icon v-else-if="notifVolume <= 66">mdi-volume-medium</v-icon>
                                    <v-icon v-else>mdi-volume-high</v-icon>
                                </v-btn>
                                <v-slider @mouseup="testNotif(notifNumber)" min="0" max="100" v-model="notifVolumeDisplayed" hide-details thumb-color="primary">
                                    <template #thumb-label="{ value }"><span class="white--text">{{ `${value} %` }}</span></template>
                                </v-slider>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-col cols="12 text-center mb-6">
            <v-btn color="error" class="me-3 mt-4" @click="SendResetEmail">Reinitialiser mon mot de passe</v-btn>
            <v-btn color="primary" class="me-3 mt-4" @click="saveChanges(false)">Sauvegarder</v-btn>
        </v-col>

        <v-row>
            <v-col cols="12" :sm="profile.role === 'SuperAdmin' && profile.dev ? '6' : ''" v-if="profile.dev">
                <v-card>
                    <v-card-title>Changer d'environement</v-card-title>
                    <v-card-text class="d-flex">
                        <b v-if="isLocalDev" class="px-3 ma-0 error--text">Mode dev activé ( LOCALHOST DETECTED )</b>
                        <span class="d-flex align-center justify-start" v-else>
                            <v-switch color="warning" class="mx-0" inset v-model="profile.envIsProd" @change="saveChanges(true)"></v-switch>
                            <b class="px-3 ma-0" v-text="profile.envIsProd ? 'Prod' : 'Dev'"></b>
                        </span>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" :sm="profile.role === 'SuperAdmin' && profile.dev ? '6' : ''" v-if="profile.role === 'SuperAdmin'">
                <v-card>
                    <v-card-title class="pb-0">Mode de session</v-card-title>
                    <v-card-text>
                        <p class="subtitle-1 mb-0">❗Changer de mode de session vous déconnecte.❗</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-col cols="12" v-if="['User', 'None'].includes(profile.role)">
            <v-card>
                <v-card-title>Rejoindre une room</v-card-title>
                <v-card-text>
                    <span class="d-flex gap-m">
                        <v-text-field type="number" label="Code de la room" dense outlined v-model="roomCode" />
                        <v-btn color="primary" @click="join">Rejoindre</v-btn>
                    </span>
                </v-card-text>
            </v-card>
        </v-col>
    </span>
</template>

<style scoped>
    * >>> .BigOutline{
        border-width: 3px;
    }

    .v-color-picker {
        width: 100%;
    }
</style>

<script>
import themeConfig from '@themeConfig'

    import logger from '@/assets/functions/logger'
    import notifier from '@/assets/functions/notifier'
    import utilities from '@/assets/functions/utilities'
    
    import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
    import Swal from 'sweetalert2/dist/sweetalert2.js'

    import Profile from '@/classes/Profile'
    import Licence from '@/classes/Licence'
    import Room from '@/classes/Room'
    import Mail from '@/classes/Mail'
    import Phone from '@/classes/Phone'
    import File from '@/classes/File'
    import Note from '@/classes/Note'
    import Event from '@/classes/Event'

	export default {
		name: 'Profil',
		props: ['user', 'profile', 'notifications', 'config', 'currentRoom'],
        setup(){
            return {
                isDark: themeConfig.app.isDark,
                auth: getAuth()
            }
        },
		data() {
			return {
                newInstance_isDark: false,
				dialog: false,
                fontSize: this.profile.fontSize,
                notifNumber : this.profile.notification,
                notifVolume: this.profile.notificationVolume,
                notifVolumeDisplayed: this.profile.notifAreMuted ? 0 : this.profile.notificationVolume,
                notifAreMuted: this.profile.notifAreMuted,
                tmp_avatar: this.profile.avatar,
                tmp_avatarColor: {
                    h: this.profile.color,
                    s: 0.5,
                    l: 0.5
                },
				profileImages: [
                    '0.png',
                    '1.png',
                    '2.png',
                    '3.png',
                    '4.png',
                    '5.png',
                    '6.png',
                    '7.png',
                    '8.png',
                    '9.png',
                    '10.png',
                    '11.png',
                    '12.png',
                    '13.png',
                    '14.png',
                    '15.png',
                    '16.png',
                    '17.png',
                    '18.png',
                    '19.png',
                    '20.png',
                    '21.png',
                    '22.png',
                    '23.png',
                    '24.png',
                    '25.png',
                    '26.png',
                    '27.png',
                    '28.png',
                    '29.png',
                    '30.png',
                    '31.png',
                    '32.png',
                    '33.png',
                    '34.png',
                ],
                licencesTable: {},
                roomsTable: {},
                roomCode: '',
                unsub: [],
			}
		},
        watch: {
            notifVolumeDisplayed(newVal, oldVal) {
                if (newVal !== 0) {
                    this.notifVolume = newVal
                    this.notifAreMuted = false
                }
            }
        },
        created() {
            this.newInstance_isDark = this.isDark === true

            this.unsub.push(Licence.listenAll( licences => {
                let tmp_association_licences = {}
                licences.forEach( licence => {
                    tmp_association_licences[licence.id] = licence
                })
                this.licencesTable = tmp_association_licences
            }))

            this.unsub.push(Room.listenAll( rooms => {
                let tmp_association_rooms = {}
                rooms.forEach( room => {
                    tmp_association_rooms[room.id] = room
                })
                this.roomsTable = tmp_association_rooms
            }))
        },
        computed: {
            availableRooms() {
                let tmp_availableRooms = []
                for (let room in this.roomsTable) {
                    if(['SuperAdmin', 'Admin'].includes(this.profile.role) || (this.profile.rooms && this.profile.rooms.includes(room))) {
                        tmp_availableRooms.push(this.roomsTable[room])
                    }
                }
                tmp_availableRooms.sort((a, b) => a.name - b.name)
                return tmp_availableRooms
            },
            isLocalDev() {
                return window.location.hostname.includes('localhost')
            }
        },
		methods: {
            updateTheme(newVal) {
                this.isDark = newVal
                this.profile.theme = newVal ? 'dark' : 'light'
                Profile.updateOnlyThemeById(this.profile.id, this.profile.theme)
            },
            changeAvatar(imgFile) {
                this.tmp_avatar = imgFile
                this.dialog = false
            },
            updateFontSize(size) {
                this.fontSize = size
                utilities.updateFontSize(size)
            },
            testNotif(nb) {
                this.notifNumber = nb
                if (!this.notifAreMuted) notifier.sound(nb, this.notifVolume)
            },
            muteNotif() {
                if (this.notifAreMuted) {
                    this.notifAreMuted = false
                    this.notifVolumeDisplayed = this.notifVolume
                } else {
                    this.notifAreMuted = true
                    this.notifVolumeDisplayed = 0
                }
            },
			saveChanges(needReload) {
                if(this.profile.currentRoom == undefined) {
                    this.profile.currentRoom = null
                }

                this.profile.avatar = this.tmp_avatar
                this.profile.fontSize = this.fontSize
                this.profile.notification = this.notifNumber
                this.profile.notificationVolume = this.notifVolume
                this.profile.notifAreMuted = this.notifAreMuted
                this.profile.color = this.tmp_avatarColor.h

                let tmp_profile = this.profile
                tmp_profile.save()
                .then(() => {
                    logger.log(this.profile.id, 'PROFILE UPDATED', this.profile.email + ' a changé son profil')
                    if (needReload) {
                        window.location.reload()
                    } else {
                        Swal.fire({
                            title: 'PROFIL MODIFIÉ',
                            text: 'Votre profil a été modifié avec succès.',
                            icon: 'success',
                            confirmButtonText: 'OK',
                        })
                    }
                })
			},
            async join(){
                if(this.roomCode){
                    let room = await Room.getByCode(this.roomCode)
                    if(room == null){
                        Swal.fire({
                            title: 'Erreur',
                            text: 'Impossible de trouver une room avec ce code.',
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    }else if(this.profile.rooms != null && this.profile.rooms.includes(room.id)){
                        Swal.fire({
                            title: 'Erreur',
                            text: 'Vous avez déjà rejoins cette room.',
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    }else if(!room.lockLevel.joining){
                        Swal.fire({
                            title: 'Erreur',
                            text: 'Cette room n\'est pas ouverte aux nouveaux membres.',
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    }else{
                        if(!this.profile.licence){
                            this.profile.licence = room.licence
                        }
                        if(this.profile.role === 'User' && !this.profile.licences.includes(room.licence)){
                            this.profile.licences.push(room.licence)
                            this.profile.licence = room.licence
                        }
                        if(this.profile.role == 'None'){
                            this.profile.role = 'User'
                        }
                        if(!this.profile.rooms){
                            this.profile.rooms = []
                        }
                        this.profile.rooms.push(room.id)
                        this.profile.currentRoom = room.id

                        if(this.profile.role == 'User'){
                            let notes = await Note.getByRoom(room.id)
                            notes.forEach(note => {
                                note.notes[this.profile.id] = {
                                    user: this.profile.id,
                                    note: 0,
                                    message: '',
                                }

                                let min = null
                                let max = null
                                let sum = 0
                                let avg = 0

                                for(let profileId in note.notes){
                                    if(!min || min > parseInt(note.notes[profileId].note)){
                                        min = parseInt(note.notes[profileId].note)
                                    }
                                    if(!max || max < parseInt(note.notes[profileId].note)){
                                        max = parseInt(note.notes[profileId].note)
                                    }
                                    sum += parseInt(note.notes[profileId].note)
                                }

                                if(min != null && max != null){
                                    avg = sum / Object.keys(note.notes).length
                                }else{
                                    min = 0
                                    max = 0
                                    avg = 0
                                }

                                note.stats = {
                                    amount: Object.keys(note.notes).length,
                                    min: min,
                                    max: max,
                                    avg: avg,
                                }

                                note.save()
                            })

                            let notifs = []

                            let mails = await Mail.getByRoom(room.id)
                            mails.forEach(mail => {
                                notifs.push({
                                    profile: this.profile.id,
                                    room: room.id,
                                    type: 'mail',
                                    date: mail.date,
                                    content: mail.id,
                                })
                            })
                            
                            let phones = await Phone.getByRoom(room.id)
                            phones.forEach(phone => {
                                notifs.push({
                                    profile: this.profile.id,
                                    room: room.id,
                                    type: 'phone',
                                    date: phone.date,
                                    content: phone.id,
                                })
                            })
                            
                            let files = await File.getByRoom(room.id)
                            files.forEach(file => {
                                notifs.push({
                                    profile: this.profile.id,
                                    room: room.id,
                                    type: 'file',
                                    date: file.date,
                                    content: null,
                                })
                            })

                            let events = await Event.getByRoomAndByType(room.id, 'global')
                            events.forEach(event => {
                                notifs.push({
                                    profile: this.profile.id,
                                    room: room.id,
                                    type: 'agenda',
                                    date: event.start,
                                    content: null,
                                })
                            })

                            for(let notif of notifs){
                                notifier.sendOne(notif.profile, notif.room, notif.type, notif.date, notif.content)
                            }
                        }

                        logger.log(this.profile.id, 'ROOM JOIN', this.profile.email + ' a rejoint la room ' + room.name)
                        this.roomCode = ''
                        this.saveChanges(true)
                    }
                }
            },
            SendResetEmail(){
                Swal.fire({
					title: 'Etes-vous sûr ?',
					icon: 'question',
					showCancelButton: true,
					confirmButtonText: 'Oui',
                    cancelButtonText: 'Annuler',
				}).then((result) => {
					if(result.isConfirmed){
                        sendPasswordResetEmail(this.auth, this.profile.email, {url: window.location.origin})
                        .then( () => {
                            Swal.fire({
                                title: 'MAIL ENVOYÉ',
                                text: 'Un e-mail a été envoyé pour réinitialiser le mot de passe (pensez à vérifier le dossier spam).',
                                icon: 'success',
                                confirmButtonText: 'OK',
                            })
                        })
					}
				})
            }
		},
        destroyed() {
            this.unsub.forEach(unsub => unsub())
            utilities.updateFontSize(this.profile.fontSize)
        },
	}
</script>
